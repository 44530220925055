import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade1",attrs:{"id":"demos"}},[_c(VCol,{staticClass:"pa-0"},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"hidden-md-and-down shade3 pt-lg-15 pb-lg-5",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase"},[_vm._v("Demos")])])],1),_vm._l((_vm.getVideoGroups(3)),function(videoGroup,groupIndex){return _c(VRow,{key:groupIndex,attrs:{"no-gutters":""}},_vm._l((videoGroup),function(video,videoIndex){return _c(VCol,{key:videoIndex,staticClass:"pa-0",attrs:{"cols":"12","lg":"4","align":"center"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pa-0"},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
                    threshold: 0.75
                  },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c(VImg,{attrs:{"src":`https://img.youtube.com/vi/${video.videoId}/maxresdefault.jpg`,"gradient":"to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)","aspect-ratio":"1.5"}},[_c(VCard,{staticClass:"fill-height d-flex justify-center",attrs:{"color":"transparent"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"pt-5"},[_c(VCol,{staticClass:"pa-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"text-center font-weight-bold text-h5 text-lg-h5 white--text pt-2",attrs:{"align":"center"}},[_c(VCol,[_vm._v(" "+_vm._s(video.title))])],1),_c(VRow,{staticClass:"text-h6 text-lg-h6 text-uppercase"},[_c(VCol,[_vm._v(" "+_vm._s(video.artist)+" ")])],1)],1)],1)],1)],1),_c('video-dialog',{attrs:{"video-id":video.videoId}})],1)],1)],1)],1)],1)],1)],1)],1)}),1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }