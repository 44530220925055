
import Vue from 'vue';

export default Vue.extend({
  components: {},
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      playerVars: {
        autoplay: 1,
      },
    };
  },
  methods: {
    openModal() {
      document.body.classList.add('modal-open');
      this.playVideo();
    },
    closeModal() {
      console.log('Closing modal');

      document.body.classList.remove('modal-open');
      this.dialog = false;
      this.player.stopVideo();
    },
    playVideo() {
      this.player.playVideo();
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    height(): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220;
        case 'sm':
          return 400;
        case 'md':
          return 500;
        case 'lg':
          return 600;
        case 'xl':
          return 800;
        default:
          return 0;
      }
    },
  },
});
