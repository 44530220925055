import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueYoutube from 'vue-youtube';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAt,
  faEnvelope,
  faPhone,
  faPlay
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPlay);
library.add(faPhone);
library.add(faAt);
library.add(faArrowDown);
library.add(faFacebookF);
library.add(faWhatsapp);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faEnvelope);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueYoutube);
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont:
      'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: true,
    themes: {
      light: {
        textColor: '#FFFFFF'
      },
      dark: {
        shade1: '#050808',
        shade2: '#091010',
        shade3: '#0E1818',
        shade4: '#132020',
        shade5: '#182828',
        shade6: '#1C2F2F',
        shade7: '#213737',
        shade8: '#263F3F',
        shade9: '#2A4747',
        shade10: '#2F4F4F',
        shade11: '#eeeeee'
      }
    }
  }
});
