
import Vue from 'vue';
import PageAppBar from '../components/PageAppBar.vue';
import PageContent from '../components/content/PageContent.vue';

export default Vue.extend({
  components: { PageContent, PageAppBar },
  data() {
  }
});
