import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade1",attrs:{"id":"about"}},[_c(VCol,{staticClass:"pa-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"shade11 hidden-md-and-down"},[_c(VCol,{staticClass:"shade1 pa-0",attrs:{"id":"title"}},[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c(VCol,[_c(VLazy,{attrs:{"options":{
                    threshold: 0.5
                  },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h1',{staticClass:"text-h2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.header)+" ")])])],1)],1)],1)],1)],1),_c(VCol,{staticClass:"px-0 py-10",attrs:{"align":"center","justify":"center"}},[_c(VLazy,{attrs:{"options":{
              threshold: 0.5
            }}},[_c('transition',{attrs:{"name":"fade"}},[_c('p',{staticClass:"shade2--text text-h6 font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.content)}})])],1)],1)],1),_c(VRow,{staticClass:"hidden-lg-and-up shade1 pt-2 pb-4"},[_c(VCol,{staticClass:"pa-0"},[_c(VContainer,{staticClass:"pt-0",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pa-5",attrs:{"align":"center"}},[_c('div',{staticClass:"text-subtitle-1 px-0",domProps:{"innerHTML":_vm._s(_vm.content)}})])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }