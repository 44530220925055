
import Vue from 'vue';
import ContactMeDialog from './content/dialogs/ContactMeDialog.vue';

export default Vue.extend({
  components: { ContactMeDialog },
  data() {
    return {
      drawer: false,
      currentWindow: 'About',
      currentTab: 'About',
      options: {
        duration: 500,
        offset: 130,
      },
      snackbar: false,
    };
  },
  methods: {
    handleEmailSent() {
      this.$emit('emailSent');
    },
    handleEmailFailed() {
      this.$emit('emailFailed');
    },
  },
});
