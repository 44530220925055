
import Vue from 'vue';
import About from './sections/About.vue';
import Demos from './sections/Demos.vue';
import Repertoire from './sections/Repertoire.vue';
import Venues from './sections/Venues.vue';
import MyStory from './sections/mystory/MyStory.vue';
import Banner from './sections/Banner.vue';
import Gallery from './sections/Gallery.vue';
import Testimonials from './sections/Testimonials.vue';
import Services from './sections/Services.vue';

export default Vue.extend({
  components: {
    Services,
    Testimonials,
    Gallery,
    Banner,
    MyStory,
    Venues,
    Repertoire,
    Demos,
    About,
  },
  watch: {},
  data() {
    return {};
  },
  methods: {},
});
