import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade1 pt-lg-15 pb-4 pb-lg-0",attrs:{"id":"services"}},[_c(VCol,[_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
            threshold: 0.5
          },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase"},[_vm._v("Services")])])],1)],1)],1),_c(VRow,{staticClass:"px-lg-1 pb-lg-1"},_vm._l((_vm.services),function(service,serviceIndex){return _c(VCol,{key:serviceIndex,staticClass:"px-md-0 pb-0 px-lg-1 py-lg-1",attrs:{"cols":"12","lg":"4"}},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
            threshold: 0.5
          },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c(VCard,{staticClass:"fill-height rounded-0 px-2 pt-2 shade8",attrs:{"align":"center"}},[_c(VImg,{attrs:{"height":"250px","src":service.image}}),_c(VCardTitle,{staticClass:"py-10"},[_c(VRow,[_c(VCol,{staticClass:"pa-0"},[_c('h3',{staticClass:"text-md-h4 font-weight-bold text-uppercase white--text"},[_vm._v(" "+_vm._s(service.title)+" ")])])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-lg-7 px-md-0 px-lg-15"},[_c('div',{staticClass:"text-md-h5 text-lg-body-1 white--text"},[_vm._v(" "+_vm._s(service.description)+" ")])])],1)],1)],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }