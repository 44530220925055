import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade5"},[_c(VCol,{staticClass:"text-center px-0 pt-0 pt-lg-15 pb-1",attrs:{"id":"repertoire"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"shade5"},[_c(VCol,[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
              threshold: 0.5
            },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase white--text"},[_vm._v(" Repertoire ")])])],1)],1)],1),_c(VRow,{staticClass:"shade7 pb-1"}),_c(VRow,{staticClass:"scrollbar scrollbar-primary shade1",style:({
          'overflow-y': 'auto',
          height: _vm.getRepertoireHeight + 'px'
        })},[_c(VCol,{staticClass:"pt-0 px-0 pb-3"},_vm._l((_vm.groupedSongs),function(group,groupIndex){return _c(VContainer,{key:groupIndex,attrs:{"fluid":""}},[_c(VRow,{staticClass:"shade2"},[_c(VCol,[_c('h4',{staticClass:"text-h4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getYearTitle(group[0]))+" ")])])],1),_c(VRow,{staticClass:"shade2"},[_c(VCol,{staticClass:"py-0"},[_c(VSimpleTable,{attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((group[1].sort((a, b) =>
                          a.name < b.name ? -1 : 1
                        )),function(song,songIndex){return _c('tr',{key:songIndex,staticClass:"shade3",staticStyle:{"border":"none !important"}},[_c('td',{staticClass:"text-right text-body-1 text-subtitle-1 text-lg-h6 px-0 py-0",staticStyle:{"width":"49.9%"},attrs:{"bgcolor":"transparent"},domProps:{"textContent":_vm._s(song.name)}}),_c('td',{staticClass:"text-center px-2 py-0",staticStyle:{"width":"0.2%"}},[_vm._v(" - ")]),_c('td',{staticClass:"text-left shade11--text text-subtitle-1 text-lg-h6 px-0 py-0",staticStyle:{"width":"49.9%"},domProps:{"textContent":_vm._s(song.artist)}})])}),0)]},proxy:true}],null,true)})],1)],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }