import { render, staticRenderFns } from "./PageAppBar.vue?vue&type=template&id=7f47c808&scoped=true"
import script from "./PageAppBar.vue?vue&type=script&lang=ts"
export * from "./PageAppBar.vue?vue&type=script&lang=ts"
import style0 from "./PageAppBar.vue?vue&type=style&index=0&id=7f47c808&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__cache-loader@4.1.0_webpack@4_wysqlyqoyvuc6jgbh4bglw2xoa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f47c808",
  null
  
)

export default component.exports