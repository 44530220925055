
import Vue from 'vue';
import TheBeginning from './TheBeginning.vue';
import ABigStep from './ABigStep.vue';
import GoingSolo from './GoingSolo.vue';
import SomeBizarreAndBeautifulMoments from './SomeBizarreAndBeautifulMoments.vue';
import GuitarsAndEquipment from './GuitarsAndEquipment.vue';
import NoArtificialIngredients from './NoArtificialIngredients.vue';

export default Vue.extend({
  components: {
    NoArtificialIngredients,
    GuitarsAndEquipment,
    SomeBizarreAndBeautifulMoments,
    GoingSolo,
    ABigStep,
    TheBeginning,
  },
  computed: {
    height(): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 1400;
        case 'sm':
          return 1400;
        case 'md':
          return 1400;
        case 'lg':
          return 800;
        case 'xl':
          return 800;
        default:
          return 0;
      }
    },
  },
});
