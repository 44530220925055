import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{attrs:{"id":"app","dark":""}},[_c(VContainer,{staticClass:"pa-0 fill-height",attrs:{"fluid":""}},[_c('page-app-bar'),_c('page-content'),_c('div',{staticStyle:{"position":"fixed","right":"5px","top":"50%","transform":"translate(0, -50%)","-ms-transform":"translateY(-50%)"}},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"fab":"","href":`tel:01621 741647`}},[_c('font-awesome-icon',{attrs:{"icon":"phone","size":"2x"}})],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"fab":"","href":`mailto:tonyjhaywood@outlook.com`}},[_c('font-awesome-icon',{attrs:{"icon":"at","size":"2x"}})],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"fab":"","href":"https://www.facebook.com/tonyhaywood24/","target":"_blank","color":"#4267B2"}},[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fab', iconName: 'facebook-f' },"size":"2x"}})],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"fab":"","href":`https://wa.me/+447905485108`,"target":"_blank","color":"#25D366"}},[_c('font-awesome-icon',{attrs:{"icon":{
                  prefix: 'fab',
                  iconName: 'whatsapp'
                },"size":"3x"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }