import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade1 pt-0 pt-lg-15",attrs:{"id":"testimonials"}},[_c(VCol,{staticClass:"pa-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"pt-2"},[_c(VCol,{attrs:{"align":"center"}},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
              threshold: 0.5
            },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase"},[_vm._v(" Testimonials ")])])],1)],1)],1),_c(VRow,[_c(VCol,[_c(VContainer,{staticClass:"hidden-md-and-down",attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.testimonials),function(testimonial,testimonialIndex){return _c(VCol,{key:testimonialIndex,staticClass:"pt-0"},[_c(VLazy,{attrs:{"options":{
                    threshold: 0.5
                  }}},[_c('transition',{attrs:{"name":"fade"}},[_c(VCard,{staticClass:"speech-bubble mb-10 pb-0"},[_c(VCardText,{staticClass:"pb-0 px-2 px-lg-5 pt-1 pt-lg-3 pb-lg-1"},[_c('p',{staticClass:"text-subtitle-1 text-lg-body-1 font-weight-bold justify-center white--text"},[_vm._v(" "+_vm._s(testimonial.content)+" ")])]),_c(VCardText,{staticClass:"testimonial-name text-right pr-1 pb-0 pt-0 shade11--text"})],1)],1)],1),_c(VLazy,{attrs:{"options":{
                    threshold: 0.5
                  }}},[_c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('div',{staticClass:"testimonial-name text-right pr-16 pb-5 pt-0 shade11--text"},[_vm._v(" "+_vm._s(testimonial.name)+" ")])])])],1)],1)}),1)],1),_c(VCarousel,{staticClass:"hidden-lg-and-up",attrs:{"id":"carousel","hide-delimiter-background":"","show-arrows-on-hover":"","cycle":"","interval":"20000","height":"auto"},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"opacity":"30%"},attrs:{"fab":"","color":"black","small":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fas', iconName: 'arrow-left' },"size":"2x"}})],1)]}},{key:"next",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"opacity":"30%"},attrs:{"fab":"","color":"black","small":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fas', iconName: 'arrow-right' },"size":"2x"}})],1)]}}])},_vm._l((_vm.getTestimonials()),function(testimonialGroup,testimonialIndex){return _c(VCarouselItem,{key:testimonialIndex,staticClass:"px-0 px-lg-15 pb-10",attrs:{"eager":""}},[_c(VRow,_vm._l((testimonialGroup),function(testimonial,testimonialIndex){return _c(VCol,{key:testimonialIndex},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pa-0"},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
                            threshold: 0.5
                          },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c(VCard,{staticClass:"speech-bubble mb-10"},[_c(VCardText,{staticClass:"pb-0 px-2 px-lg-5 pt-1 pt-lg-3 pb-lg-1"},[_c('p',{staticClass:"text-subtitle-1 text-lg-body-1 font-weight-bold justify-center white--text"},[_vm._v(" "+_vm._s(testimonial.content)+" ")])])],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pa-0"},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
                            threshold: 0.5
                          },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('p',{staticClass:"testimonial-name text-right pr-1 pb-5 pt-0 shade11--text"},[_vm._v(" "+_vm._s(testimonial.name)+" ")])])],1)],1)],1)],1)],1)}),1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }