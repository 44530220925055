
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      viewportHeight: 0,
      currentTab: 'Alphabetical',
      songs: [
        { name: '(The) Wild Rover', artist: 'Traditional Irish', year: null },
        { name: 'Molly Malone', artist: 'Traditional Irish', year: null },
        { name: 'Romance', artist: 'Spanish Instrumental', year: null },
        {
          name: 'Daisy Bell (Bicycle Built for Two)',
          artist: 'Harry Dacre ',
          year: 1892
        },
        {
          name: 'Underneath the Arches',
          artist: 'Flanagan & Allen ',
          year: 1932
        },
        { name: 'We’ll Meet Again', artist: 'Vera Lynn ', year: 1939 },
        {
          name: 'The White Cliffs of Dover',
          artist: 'Vera Lynn ',
          year: 1941
        },
        { name: 'As Time Goes By', artist: 'Dooley Wilson ', year: 1942 },
        { name: 'If I Had a Hammer', artist: 'Trini Lopez ', year: 1949 },
        { name: 'Dirty Old Town', artist: 'Ewan Maccoll ', year: 1949 },
        {
          name: 'My Resistance is Low',
          artist: 'Hoagy Carmichael ',
          year: 1951
        },
        { name: 'Unforgettable', artist: 'Nat King Cole ', year: 1951 },
        {
          name: 'Lullaby of Birdland',
          artist: 'George Shearing ',
          year: 1952
        },
        { name: 'Be Bop a Lula', artist: 'Gene Vincent ', year: 1956 },
        { name: 'Blue Suede Shoes', artist: 'Elvis Presley ', year: 1956 },
        { name: 'Love Me Tender', artist: 'Elvis Presley ', year: 1956 },
        { name: 'Que Sera Sera', artist: 'Doris Day ', year: 1956 },
        { name: 'Singing the Blues', artist: 'Guy Mitchel ', year: 1956 },
        { name: 'Bye Bye Love', artist: 'Everly Brothers ', year: 1957 },
        { name: 'Everyday', artist: 'Buddy Holly ', year: 1957 },
        {
          name: 'Wake Up Little Susie',
          artist: 'Everly Brothers ',
          year: 1957
        },
        {
          name: 'All I Have to do is Dream',
          artist: 'Everly Brothers ',
          year: 1958
        },
        { name: 'Edelweiss', artist: 'Rodgers and Hammerstein ', year: 1959 },
        {
          name: 'Put Your Sweet Lips Closer to the Phone',
          artist: 'Jim Reeves',
          year: 1959
        },
        {
          name: 'Love Hurts',
          artist: 'Everly Brothers/Jim Capaldi ',
          year: 1960
        },
        { name: 'Stand By Me', artist: 'Ben E King ', year: 1960 },
        { name: 'True Love Ways', artist: 'Buddy Holly ', year: 1960 },
        { name: 'Fly me to the Moon', artist: 'Nat King Cole ', year: 1961 },
        {
          name: '(The) Lion Sleeps Tonight',
          artist: 'Carl Denver/Tight Fit ',
          year: 1961
        },
        { name: 'Moon River', artist: 'Danny/Andy Williams', year: 1961 },
        { name: '(The) Wanderer', artist: 'Dion ', year: 1961 },
        {
          name: 'When the Girl in Your Arms….',
          artist: 'Cliff Richard ',
          year: 1961
        },
        { name: 'Blowing in the Wind', artist: 'Bob Dylan ', year: 1962 },
        {
          name: 'Can`t Help Falling in Love',
          artist: 'Elvis Presley ',
          year: 1962
        },
        { name: 'Crazy', artist: 'Patsy Cline ', year: 1962 },
        { name: 'Return to Sender', artist: 'Elvis Presley ', year: 1962 },
        { name: '(The) Young Ones', artist: 'Cliff Richard ', year: 1962 },
        {
          name: 'Can`t Get Used to Losing You',
          artist: 'Andy Williams',
          year: 1963
        },
        { name: 'I Saw Her Standing There', artist: 'Beatles ', year: 1963 },
        { name: 'Up on the Roof', artist: 'The Drifters ', year: 1963 },
        { name: 'And I Love Her', artist: 'Beatles ', year: 1964 },
        { name: 'Eight Days a Week', artist: 'Beatles ', year: 1964 },
        {
          name: 'Don`t Let the Sun Catch you Crying',
          artist: 'Gerry and the PM',
          year: 1964
        },
        {
          name: 'Everybody Loves Somebody',
          artist: 'Dean Martin ',
          year: 1964
        },
        {
          name: 'Ferry `Cross the Mersey',
          artist: 'Gerry+ Pacemakers ',
          year: 1964
        },
        {
          name: 'House of the Rising Sun',
          artist: 'The Animals ',
          year: 1964
        },
        {
          name: 'I’m into Something Good',
          artist: 'Hermans Hermits ',
          year: 1964
        },
        { name: 'Under the Boardwalk', artist: 'The Drifters ', year: 1964 },
        {
          name: 'California Dreamin`',
          artist: 'Mamas and Papas ',
          year: 1965
        },
        { name: 'In my Life', artist: 'Beatles ', year: 1965 },
        { name: 'It`s Not Unusual', artist: 'Tom Jones ', year: 1965 },
        {
          name: 'King of the Road',
          artist: 'Roger Miller/Proclaimers ',
          year: 1965
        },
        { name: 'Norwegian Wood', artist: 'Beatles ', year: 1965 },
        {
          name: 'Unchained Melody',
          artist: 'Righteous Brothers ',
          year: 1965
        },
        {
          name: '(A) Windmill in Old Amsterdam',
          artist: 'Ronnie Hilton ',
          year: 1965
        },
        { name: 'Yesterday', artist: 'Beatles ', year: 1965 },
        {
          name: 'You`ve Got to Hide Your Love Away',
          artist: 'Beatles',
          year: 1965
        },
        { name: 'I’m a Believer', artist: 'The Monkeys ', year: 1966 },
        {
          name: 'Leaving on a Jet Plane',
          artist: 'John Denver/Peter Paul & Mary',
          year: 1966
        },
        { name: 'Monday Monday', artist: 'Mamas and Papas ', year: 1966 },
        {
          name: 'Sound of Silence',
          artist: 'Simon and Garfunkal ',
          year: 1966
        },
        { name: 'Spanish Eyes', artist: 'Al Martino ', year: 1966 },
        { name: 'Wild Thing', artist: 'Troggs ', year: 1966 },
        { name: 'Yellow Submarine', artist: 'Beatles ', year: 1966 },
        { name: 'Brown Eyed Girl', artist: 'Van Morrison ', year: 1967 },
        { name: 'Daydream Believer', artist: 'The Monkeys ', year: 1967 },
        { name: 'Happy Together', artist: 'Turtles ', year: 1967 },
        { name: 'Hey Joe', artist: 'Jimi Hendrix ', year: 1967 },
        { name: 'Hi Ho Silver Lining', artist: 'Jeff Beck ', year: 1967 },
        {
          name: 'Love is all Around',
          artist: 'Troggs/Wet Wet Wet ',
          year: 1967
        },
        { name: 'People are Strange', artist: 'The Doors ', year: 1967 },
        { name: 'Ruby Tuesday', artist: 'The Rolling Stones ', year: 1967 },
        {
          name: 'What a Wonderful World',
          artist: 'Louie Armstrong',
          year: 1967
        },
        { name: 'When I’m Sixty Four', artist: 'Beatles ', year: 1967 },
        {
          name: '(A) Whiter Shade of Pale',
          artist: 'Procal Harem ',
          year: 1967
        },
        {
          name: 'Build me up Buttercup',
          artist: 'The Foundations ',
          year: 1968
        },
        {
          name: 'Can’t Take My Eyes Off You',
          artist: 'Andy Williams ',
          year: 1968
        },
        { name: 'Congratulations', artist: 'Cliff Richard ', year: 1968 },
        { name: 'Classical Gas', artist: 'Mason Williams ', year: 1968 },
        { name: 'Days', artist: 'Kinks/Kirsty Mac Call ', year: 1968 },
        { name: 'Hey Jude', artist: 'Beatles ', year: 1968 },
        { name: 'Lazy Sunday Afternoon', artist: 'Small Faces ', year: 1968 },
        {
          name: 'Mrs Robinson',
          artist: 'Simon and Garfunkal/Lemonheads ',
          year: 1968
        },
        {
          name: 'Need Your Love So Bad',
          artist: 'Fleetwood Mac ',
          year: 1968
        },
        {
          name: 'Sittin’ on the Dock of the Bay',
          artist: 'Ottis Reading ',
          year: 1968
        },
        { name: 'Those Were the Days', artist: 'Mary Hopkin ', year: 1968 },
        { name: 'Wichita Lineman', artist: 'Glen Cambell ', year: 1968 },
        { name: 'Both Sides Now', artist: 'Joni Mitchell ', year: 1969 },
        { name: 'Durham Town', artist: 'Roger Whittaker ', year: 1969 },
        { name: 'He Ain’t Heavy', artist: 'The Hollies ', year: 1969 },
        { name: 'Here Comes the Sun', artist: 'Beatles ', year: 1969 },
        {
          name: 'I`ll Never Fall in Love Again',
          artist: 'Deacon Blue',
          year: 1969
        },
        { name: 'In the Ghetto', artist: 'Elvis Presley ', year: 1969 },
        { name: 'Man of the World', artist: 'Fleetwood Mac ', year: 1969 },
        { name: 'My Way', artist: 'Frank Sinatra ', year: 1969 },
        {
          name: 'Proud Mary',
          artist: 'Creedence Clearwater Revival ',
          year: 1969
        },
        { name: 'Something', artist: 'Beatles ', year: 1969 },
        { name: 'Space Oddity', artist: 'David Bowie ', year: 1969 },
        {
          name: 'Spirit in the Sky',
          artist: 'Norman Greenbaum ',
          year: 1969
        },
        { name: 'Streets of London', artist: 'Ralph Mctell ', year: 1969 },
        { name: 'Sweet Caroline', artist: 'Neil Diamond ', year: 1969 },
        {
          name: 'And I Love You So',
          artist: 'Perry Como /Don McLean ',
          year: 1970
        },
        {
          name: 'Cavatina',
          artist: 'John Williams (The Deerhunter) ',
          year: 1970
        },
        { name: 'Close to You', artist: 'Carpenters ', year: 1970 },
        {
          name: 'Father and Son',
          artist: 'Cat Stevens/Boyzone ',
          year: 1970
        },
        { name: 'Fire and Rain', artist: 'James Taylor ', year: 1970 },
        {
          name: 'Help Me Make it Through the Night',
          artist: 'K Kristofferson',
          year: 1970
        },
        {
          name: 'If you Could Read my Mind',
          artist: 'Gordon Lightfoot',
          year: 1970
        },
        { name: 'Let It Be', artist: 'Beatles ', year: 1970 },
        { name: 'Lola', artist: 'The Kinks ', year: 1970 },
        {
          name: 'Love of the Common People',
          artist: 'Nicky Thomas ',
          year: 1970
        },
        { name: 'Moondance', artist: 'Van Morrison ', year: 1970 },
        { name: 'We`ve Only Just Begun', artist: 'Carpenters ', year: 1970 },
        { name: 'Wild World', artist: 'Cat Stevens ', year: 1970 },
        { name: '(The) Wonder of You', artist: 'Elvis Presley ', year: 1970 },
        {
          name: 'Woodstock',
          artist: 'Matthews Southern Comfort ',
          year: 1970
        },
        { name: 'Your Song', artist: 'Elton John ', year: 1970 },
        { name: 'American Pie', artist: 'Don Maclain ', year: 1971 },
        { name: 'If', artist: 'David Gates ', year: 1971 },
        { name: 'Imagine', artist: 'John Lennon ', year: 1971 },
        {
          name: 'Is This the Way to Amarillo',
          artist: 'Tony Christie ',
          year: 1971
        },
        {
          name: 'It Must Be Love',
          artist: 'Labi Siffre/Madness ',
          year: 1971
        },
        {
          name: 'Jealous Guy',
          artist: 'John Lennon/Roxy Music ',
          year: 1971
        },
        { name: 'Layla', artist: 'Eric Clapton ', year: 1971 },
        { name: 'Life on Mars', artist: 'David Bowie', year: 1971 },
        { name: 'Life`s a Long Song', artist: 'Jethro Tull ', year: 1971 },
        { name: 'Riders of the Storm', artist: 'The Doors ', year: 1971 },
        { name: 'Superstar', artist: 'Carpenters ', year: 1971 },
        { name: 'Heart of Gold', artist: 'Neil Young ', year: 1972 },
        { name: '(A) Horse With No Name', artist: 'America ', year: 1972 },
        { name: 'I Can See Clearly Now', artist: 'Johnny Nash ', year: 1972 },
        { name: 'Perfect Day', artist: 'Lou Reed ', year: 1972 },
        { name: 'Play Me', artist: 'Neil Diamond ', year: 1972 },
        { name: 'Song Sung Blue', artist: 'Neil Diamond ', year: 1972 },
        {
          name: 'Stuck in the Middle',
          artist: 'Steelers Wheels ',
          year: 1972
        },
        { name: 'Vincent', artist: 'Don McLean ', year: 1972 },
        {
          name: 'Killing me Softly',
          artist: 'Roberta Flack/Fugees ',
          year: 1973
        },
        { name: 'Desperado', artist: 'Eagles ', year: 1973 },
        {
          name: 'Seasons in the Sun',
          artist: 'Terry Jacks/Westlife ',
          year: 1973
        },
        {
          name: '(The) Air That I Breathe',
          artist: 'The Hollies ',
          year: 1974
        },
        { name: 'Annie’s Song', artist: 'John Denver ', year: 1974 },
        {
          name: 'Cat’s in the Cradle',
          artist: 'Harry Chapin/Ugly Kid Joe ',
          year: 1974
        },
        { name: 'Mandy', artist: 'Barry Manilow ', year: 1974 },
        { name: 'Lyin Eyes', artist: 'Eagles ', year: 1975 },
        { name: 'Sailing', artist: 'Rod Stewart ', year: 1975 },
        { name: 'Wish You Were Here', artist: 'Pink Floyd ', year: 1975 },
        { name: 'Don’t Go Changing', artist: 'Billy Joel ', year: 1977 },
        {
          name: 'Dont it Make my Brown Eyes Blue',
          artist: 'CrystalGayle ',
          year: 1977
        },
        { name: 'Lay Down Sally', artist: 'Eric Clapton ', year: 1977 },
        { name: 'More Than A Woman', artist: 'Bee Gees ', year: 1977 },
        { name: 'Mull of Kintyre', artist: 'Wings ', year: 1977 },
        { name: '(The) Name of the Game', artist: 'Abba ', year: 1977 },
        { name: 'We Are The Champions', artist: 'Queen ', year: 1977 },
        { name: 'When I Need You', artist: 'Leo Sayer ', year: 1977 },
        { name: 'Wonderful Tonight', artist: 'Eric Clapton ', year: 1977 },
        {
          name: 'Always on My Mind',
          artist: 'Elvis Presley/Willie Nelson ',
          year: 1978
        },
        { name: 'Because the Night', artist: 'Patti Smith ', year: 1978 },
        {
          name: 'Fool if you Think it`s Over',
          artist: 'Chris Rea ',
          year: 1978
        },
        {
          name: 'Forever Autumn',
          artist: 'Justin Haywood/War of the Worlds',
          year: 1978
        },
        { name: 'I Can`t Stop Loving You', artist: 'Leo Sayer ', year: 1978 },
        { name: 'So Lonely', artist: 'Police ', year: 1978 },
        { name: 'Three Times a Lady', artist: 'Lionel Richie ', year: 1978 },
        { name: 'You Needed Me', artist: 'Ann Murrey/Boyzone ', year: 1978 },
        { name: 'You’re in My Heart', artist: 'Rod Stewart ', year: 1978 },
        {
          name: 'You`re the One That I Want',
          artist: '“Grease” ',
          year: 1978
        },
        {
          name: 'Crazy Little Thing Called Love',
          artist: 'Queen ',
          year: 1979
        },
        {
          name: '(It’s) Different for Girls',
          artist: 'Joe Jackson ',
          year: 1979
        },
        { name: 'My Girls Mad at Me', artist: 'Madness ', year: 1979 },
        { name: 'Love on the Rocks', artist: 'Neil Diamond ', year: 1980 },
        { name: 'Romeo and Juliet', artist: 'Dire Straits ', year: 1981 },
        { name: 'Woman', artist: 'John Lennon ', year: 1981 },
        { name: 'Blue Eyes', artist: 'Elton John ', year: 1982 },
        { name: 'Heartbreaker', artist: 'Dione Warwick ', year: 1982 },
        {
          name: 'Mad World',
          artist: 'Tears for Fears/Gary Jules ',
          year: 1982
        },
        { name: 'I’m Still Standing', artist: 'Elton John ', year: 1983 },
        {
          name: 'Hallelujah',
          artist: 'Leonard Cohen/Jeff Buckley ',
          year: 1984
        },
        { name: 'Hello', artist: 'Lionel Ritchie ', year: 1984 },
        { name: 'Careless Whisper', artist: 'George Michael ', year: 1985 },
        { name: 'The Lady in Red', artist: 'Chris De Burgh ', year: 1986 },
        { name: 'On the Beach', artist: 'Chris Rea ', year: 1986 },
        {
          name: 'Through the Barricades',
          artist: 'Spandau Ballet',
          year: 1986
        },
        {
          name: "I'm Gonna Be (500 Miles)",
          artist: 'The Proclaimers',
          year: 1988
        },
        { name: 'Eternal Flame', artist: 'The Bangles ', year: 1989 },
        {
          name: 'Have I Told You Lately',
          artist: 'Van Morrison ',
          year: 1989
        },
        { name: 'Wind Beneath my Wings', artist: 'Bett Midler ', year: 1989 },
        {
          name: 'Everything I do I do it for You',
          artist: 'Bryan Adams',
          year: 1991
        },
        { name: 'One', artist: 'U2 ', year: 1992 },
        { name: 'Tears in Heaven', artist: 'Eric Clapton ', year: 1992 },
        { name: "Creep", artist: "Radiohead", year: 1992},
        {
          name: 'Under the Bridge',
          artist: 'Red Hot Chilli Peppers ',
          year: 1992
        },
        { name: 'Everybody Hurts', artist: 'REM ', year: 1993 },
        { name: '‘74-‘75', artist: 'The Connells ', year: 1993 },
        {
          name: 'I’ll Stand By You',
          artist: 'Pretenders/Girls Aloud ',
          year: 1994
        },
        { name: 'Zombie', artist: 'The Cranberries ', year: 1994 },
        { name: 'Hurt', artist: 'Johnny Cash/ Nine Inch Nails ', year: 1995 },
        { name: 'Wonderwall', artist: 'Oasis ', year: 1995 },
        {
          name: 'You do Something to me',
          artist: 'Paul Weller ',
          year: 1995
        },
        { name: 'Don`t Look Back in Anger', artist: 'Oasis ', year: 1996 },
        {
          name: "You've Got a Friend in Me ",
          artist: 'Randy Newman',
          year: 1996
        },
        { name: 'Angels', artist: 'Robbie Williams ', year: 1997 },
        { name: '(The) Drugs Don’t Work', artist: 'The Verve', year: 1997 },
        { name: 'From This Moment', artist: 'Shania Twain ', year: 1998 },
        { name: 'No Matter What', artist: 'Boyzone ', year: 1998 },
        { name: 'Three Lions', artist: 'Frank Skinner ', year: 1998 },
        { name: 'Babylon', artist: 'David Gray ', year: 1999 },
        {
          name: 'When You Say Nothing at All',
          artist: 'Ronan Keating',
          year: 1999
        },
        { name: 'Trouble', artist: 'Coldplay ', year: 2000 },
        { name: 'Sail Away', artist: 'David Gray ', year: 2001 },
        { name: 'Leave Right Now', artist: 'Will Young ', year: 2003 },
        {
          name: 'Boulevard of Broken Dreams',
          artist: 'Green Day ',
          year: 2004
        },
        {
          name: 'Somewhere Only We Know',
          artist: 'Lily Allen/Keane ',
          year: 2004
        },
        { name: 'You’re Beautiful', artist: 'James Blunt ', year: 2005 },
        {
          name: 'Crazy',
          artist: 'Gnarls Barkley / Nelly Furtado ',
          year: 2006
        },
        { name: 'Patience', artist: 'Take That ', year: 2006 },
        { name: '(The) Fear', artist: 'Lilly Allen ', year: 2008 },
        { name: 'Rolling in the Deep', artist: 'Adele ', year: 2010 },
        { name: 'A Team', artist: 'Ed Sheeran ', year: 2011 },
        { name: 'Counting Stars', artist: 'OneRepublic ', year: 2013 },
        { name: 'Get Lucky', artist: 'Daft Punk ', year: 2013 },
        { name: 'Wrecking Ball', artist: 'Miley Cyrus ', year: 2013 },
        {
          name: 'I’m Not Going to Miss You',
          artist: 'Glen Cambell’s last song ',
          year: 2014
        },
        { name: 'Writing on the wall', artist: 'Sam Smith ', year: 2015 },
        { name: 'Cheap Thrills', artist: 'Sia ', year: 2016 },
        { name: 'Human', artist: 'Rag’n’Bone Man ', year: 2016 },
        { name: 'Skin', artist: 'Rag’n’Bone Man ', year: 2017 },
        { name: 'Flames', artist: 'Sia ', year: 2018 },
        { name: 'Shotgun', artist: 'George Ezra', year: 2018 },
        { name: 'Someone You Loved', artist: 'Lewis Capaldi ', year: 2018 },
        { name: 'Blinding Lights', artist: 'The Weeknd', year: 2019 },
        { name: 'Dance Monkey', artist: 'Tones and I', year: 2019 },
        { name: 'Watermelon Sugar', artist: 'Harry Styles', year: 2019 },
        { name: 'Bad Habits', artist: 'Ed Sheeran', year: 2021 },
        { name: 'Flowers', artist: 'Miley Cyrus', year: 2023 }
      ]
    };
  },
  mounted() {
    this.viewportHeight = this.$vuetify.breakpoint.height;
  },
  methods: {
    getYearTitle(year: number): string {
      const yearString: string = '' + year;

      if (yearString === '1900') {
        return '1900s';
      } else if (yearString.startsWith('19')) {
        return `${yearString.slice(yearString.length - 2)}s`;
      } else if (yearString.startsWith('20')) {
        return `${yearString}s`;
      } else {
        return `< 1900s`;
      }
    },
    getGroupedSongs(): Map<
      number,
      Array<{ artist: string; year: number; name: string }>
      > {
      const groupedSongs = new Map<
        number,
        Array<{ artist: string; year: number; name: string }>
        >();

      this.songs.forEach((song) => {
        const year = song.year;

        const decade = year ? Math.floor(year / 10) * 10 : null;

        if (groupedSongs.has(decade)) {
          const songs = groupedSongs.get(decade);
          songs.push(song);
          groupedSongs.set(decade, songs);
        } else {
          groupedSongs.set(decade, [song]);
        }
      });
      return groupedSongs;
    }
  },
  computed: {
    getRepertoireHeight(): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return this.viewportHeight / 2;
        case 'lg':
        case 'xl':
        default:
          return (this.viewportHeight / 3) * 2;
      }
    },
    groupedSongs(): Map<
      number,
      Array<{ artist: string; year: number; name: string }>
      > {
      const groupedSongs: Map<number, {artist: string, year: number, name: string}[]> = this.getGroupedSongs();

      return  new Map(Array.from(groupedSongs).reverse());
    }
  }
});
