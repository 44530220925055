import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade1",attrs:{"id":"my-story"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"shade1 pt-lg-15"},[_c(VCol,{attrs:{"align":"center"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase white--text"},[_vm._v(" My Story ")])])],1),_c(VRow,{staticClass:"hidden-md-and-down"},[_c(VCol,{staticClass:"ma-0 white--text pb-0 px-0"},[_c(VCarousel,{attrs:{"height":_vm.height}},[_c(VCarouselItem,{staticClass:"fill-height mx-0 mx-lg-5"},[_c('the-beginning')],1),_c(VCarouselItem,{staticClass:"fill-height mx-lg-5"},[_c('a-big-step')],1),_c(VCarouselItem,{staticClass:"fill-height mx-lg-5"},[_c('going-solo')],1),_c(VCarouselItem,{staticClass:"fill-height mx-lg-5"},[_c('no-artificial-ingredients')],1),_c(VCarouselItem,{staticClass:"fill-height mx-lg-5"},[_c('some-bizarre-and-beautiful-moments')],1),_c(VCarouselItem,{staticClass:"fill-height mx-lg-5"},[_c('guitars-and-equipment')],1)],1)],1)],1),_c(VRow,{staticClass:"hidden-lg-and-up"},[_c(VCol,{staticClass:"ma-0 white--text pb-0 px-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c('the-beginning'),_c('a-big-step'),_c('going-solo'),_c('no-artificial-ingredients'),_c('some-bizarre-and-beautiful-moments'),_c('guitars-and-equipment')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }