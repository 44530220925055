
import Vue from 'vue';
import VideoDialog from '../dialogs/VideoDialog.vue';

export default Vue.extend({
  components: { VideoDialog },
  data() {
    return {
      videos: [
        {
          title: 'AMERICAN PIE',
          artist: 'Don McLean',
          videoId: 'Y4TjtPTe8Sc',
          colour: 'rgba(135,135,135,1)'
        },
        {
          title: 'CATS IN THE CRADLE',
          artist: 'Harry Chaplin',
          videoId: 'abf-BYNtYLM',
          colour: 'rgba(128,128,128,1)'
        },
        {
          title: 'SKIN',
          artist: "Rag'n'Bone Man",
          videoId: 'icCZYAegSNA',
          colour: 'rgba(147,147,147,1)'
        }
      ]
    };
  },
  methods: {
    getVideoGroups(groupSize: number): Array<Array<Object>> {
      const groups = [];

      let i, j;

      for (i = 0, j = this.videos.length; i < j; i += groupSize) {
        groups.push(this.videos.slice(i, i + groupSize));
      }

      return groups;
    }
  }
});
