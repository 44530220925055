import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":"https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png","transition":"scale-transition","width":"40"}}),_c(VImg,{staticClass:"shrink mt-1 hidden-sm-and-down",attrs:{"alt":"Vuetify Name","contain":"","min-width":"100","src":"https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png","width":"100"}})],1),_c(VSpacer),_c(VBtn,{attrs:{"href":"https://github.com/vuetifyjs/vuetify/releases/latest","target":"_blank","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Latest Release")]),_c(VIcon,[_vm._v("mdi-open-in-new")])],1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }