
import Vue from 'vue';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {
      currentTab: 'Alphabetical',
      venues: [
        { name: 'Ancora Restaurant', location: 'Hadleigh', hide: false },
        { name: 'Akropolis Restaurant', location: 'Westcliff', hide: false },
        { name: 'Azzurro Restaurant', location: 'Westcliff', hide: false },
        { name: 'Candles Inn', location: 'Westcliff', hide: false },
        { name: 'The Green Man PH', location: 'Bradwell', hide: false },
        { name: 'Indian Ruchi', location: 'Billericay', hide: false },
        { name: 'Labworth Restaurant', location: 'Canvey', hide: true },
        {
          name: 'McDowells Pie & Mash',
          location: 'Chelmsford & Basildon',
          hide: true,
        },
        { name: 'Mouchak Y2K', location: 'Downham', hide: false },
        { name: 'Park View', location: 'Chalkwell', hide: false },
        { name: 'The Rose Inn', location: 'Great Wakering', hide: false },
      ]
        .sort((venue1, venue2) => (venue1.name < venue2.name ? -1 : 1))
        .filter((venue) => !venue.hide),
    };
  },
});
