
import Vue from 'vue';
import { getGroups } from '../../../utils/HelperUtils';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {
      testimonials: [
        {
          name: 'Robin Bryant',
          content:
            'Tony is just a lovely and extremely talented musician. Whilst i have never personally played a guitar, I think he is totally professional in his abilities.\n'
            + 'But, Tony is much more than this, he is warm hearted and generous in his comments. He knows the history of the songs he sings, and his repertoire is just vast! He is a regular visitor to an Over 55s Club I organise, and Tony is a favourite entertaining guest.',
        },
        {
          name: 'Nicola Gardner',
          content:
            "Tony has been a friend for years & is a lovely guy would do anything for us. He played in our garden this weekend at our family bbq & was just fantastic, I reiterate everything Robin has put in the previous review. The choice of songs cover every age group & I would not hesitate in recommending him for any event. Tony you made our celebration complete & you made my parents' year.  Great surprise for everyone & would love to have you back.  Supporting local talent is more important than ever now, so c'mon everyone 'do it'. Thank you so much Tony x",
        },
        {
          name: 'Southend U3A',
          content:
            'This September, Southend U3A were able to restart our Tuesday afternoon meetings. Tony Haywood was our obvious choice and we were delighted to welcome him back. With his superb list of songs and gentle banter he was able brought us a step nearer to normality.',
        },
      ],
    };
  },
  methods: {
    /* eslint-disable */
    getTestimonials(): Array<Array<Object>> {
      return getGroups(this.testimonials, this.testimonialsPerRow);
    },
  },
  computed: {
    testimonialsPerRow(): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 1;
        case 'sm':
          return 1;
        case 'md':
          return 1;
        case 'lg':
          return 1;
        case 'xl':
          return 1;
        default:
          return 1;
      }
    },
  },
});
