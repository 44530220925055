import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"pb-1 pt-lg-15 shade3",attrs:{"id":"venues"}},[_c(VCol,{staticClass:"pa-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
              threshold: 0.5
            },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase white--text text-center"},[_vm._v(" Previous and current venues ")])])],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.venues),function(venue,venueIndex){return _c(VCol,{key:venueIndex,staticClass:"white--text pa-1",attrs:{"cols":"12","lg":"4","align":"center"}},[_c(VLazy,{attrs:{"options":{
              threshold: 1
            }}},[_c('transition',{attrs:{"name":"fade"}},[_c(VCard,{staticClass:"pa-0 shade2 rounded-0 fill-height"},[_c(VCardTitle,{staticClass:"text-uppercase pa-2 white--text justify-center"},[_vm._v(" "+_vm._s(venue.name)+" ")]),_c(VCardText,{staticClass:"px-0 pt-0 pb-2 shade11--text"},[_vm._v(" "+_vm._s(venue.location)+" ")])],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }