import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"shade2 pt-lg-15",attrs:{"id":"gallery"}},[_c(VCol,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"px-0 pt-0 pb-5",attrs:{"align":"center"}},[_c(VLazy,{staticClass:"fill-height",attrs:{"options":{
              threshold: 0.5
            },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase"},[_vm._v("GALLERY")])])],1)],1)],1),_c(VRow,{attrs:{"id":"demos"}},[_c(VCol,_vm._l((_vm.getImageGroups(3)),function(imageGroup,imageGroupIndex){return _c(VRow,{key:imageGroupIndex,staticClass:"image"},_vm._l((imageGroup),function(image,imageIndex){return _c(VCol,{key:imageIndex,staticClass:"pa-0",attrs:{"cols":"12","lg":"4"}},[_c(VLazy,{attrs:{"options":{
                  threshold: 1
                }}},[_c('transition',{attrs:{"name":"fade"}},[_c(VImg,{staticClass:"gallery-image",attrs:{"src":image,"aspect-ratio":"0.7"}})],1)],1)],1)}),1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }