
import Vue from 'vue';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {
      services: [
        {
          title: 'Weddings',
          image: '/services/weddings-low-res.jpg',
          description:
            'I can play during Arrivals, Drinks and Wedding Breakfast. Going that extra mile to complete your idyllic day.',
        },
        {
          title: 'Restaurants',
          image: '/services/restaurants-low-res.jpg',
          description:
            'A residential musician creating a subtle, intimate atmosphere where patrons can comfortably converse with their friends or just sit back and listen. Requests can be actively encouraged by passing out song menus.',
        },
        {
          title: 'Events',
          image: '/services/events-low-res.jpg',
          description:
            'Pubs, clubs and corporate functions can benefit from an original act with a set built from requests from the audience. No venue too diverse or audience too small. Make memories of sunshine and music with your next BBQ/garden party.',
        },
      ],
    };
  },
  methods: {},
});
